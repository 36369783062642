.App{
  text-align: center;
}

.list{
  list-style-type: none;
  margin: 0;

}

.list2{
  list-style-type: none;
  margin: 0;
  margin-left: 1180px;
}


.section {
  float: left;
}

.section2 {
  float: left;
}

#navbar{
  display: flex;
  position: fixed;
  background-color: purple;
  width: 100%;
}

.section a {
	display: block;
	color: white;
	text-decoration: none;
	padding: 18px 20px;
	text-align: center;
}

.section2 a {
  display: block;
  color: white;
  padding: 18px 20px;
}

.section a:hover {
	background-color: #111;
}

.section2 a:hover {
	background-color: #111;
}

.top{
  height: 25px;
}



#welcome{
  display: flex;
	flex-direction: column;
	background-color: black;
	color: white;
	text-align: center;
	font-size: 25px;
  height: 62vh;
  padding-top: 370px;
}

#contact {
  background-color: black;
  color: white;
  position: relative;
  height: 25vh;
}

.proj{
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.project-tile{
  padding-right: 25px;
}

.project-tile2{
  padding-right: 25px;
  padding-top: 25px;
}

.proj-img{
  height: 650px;
}

.push{
  padding-bottom: 150px;
}

#projects{
  border-bottom: 5px solid black;
}

.description{
  font-size: 20px;
}

.description2{
  font-size: 18px;
}

.Icons{
  height: 60px;
  padding-left: 45px;
  padding-right: 50px;
  color: white;
}

.Icons:hover{
  color: purple;
}

.learn-img2 {

}



/* Older Phones */
/* Portrait and Landscape */

/* none plusses */
@media only screen 
  and (min-device-width: 375px) 
  and (max-device-width: 667px) 
  and (-webkit-min-device-pixel-ratio: 2) { 

    #welcome{
      padding-top: 250px;
    }

    #navbar{
      display: flex;
      flex-direction: row;
      position: static;
    }

    .section{
      font-size: 12px;
    }

    .section2{

    }

    .list2{
      margin-left: 0px;
    }
    .proj{
      display: flex;
      flex-direction: row;
      justify-content: center;
    }

    #contact{
      position: relative;
      height: 35vh;
    }

    .learn-img2{
      width: 98%;
    }

}

/* Iphone plusses */

@media only screen 
  and (min-device-width: 414px) 
  and (max-device-width: 736px) 
  and (-webkit-min-device-pixel-ratio: 3) { 
    #welcome{
      padding-top: 250px;
    }

    #navbar{
      display: flex;
      flex-direction: row;
      position: static;

    }
    .list2{
      margin-left: 0px;
    }
    .proj{
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    #contact{
      position: relative;
      height: 35vh;
    }

    .learn-img2{
      width: 98%;
    }

}

/* Iphone X */
@media only screen 
  and (min-device-width: 375px) 
  and (max-device-width: 812px) 
  and (-webkit-min-device-pixel-ratio: 3) { 

    #welcome{
      padding-top: 250px;
    }

    #navbar{
      display: flex;
      flex-direction: row;
      position: static;
    }
    .list2{
      margin-left: 0px;
    }
    .proj{
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    #contact{
      position: relative;
      height: 35vh;
    }

    .learn-img2{
      width: 98%;
    }

}